.button-wrapper {
  background: linear-gradient(116.56deg, #2665ff 0%, #36b1f2 100%);
  border-radius: 10px;
  cursor: pointer;
  width: 165px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    color: #fff;
    font-weight: 500;
  }
}

.about-page-button {
  background: linear-gradient(116.56deg, #2665ff 0%, #36b1f2 100%);
  border-radius: 10px;
  cursor: pointer;
  max-width: 343px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    color: #fff;
    font-weight: 500;
  }
}

.footer2 {
  background: linear-gradient(116.56deg, #2665ff 0%, #36b1f2 100%);
  border-radius: 10px;
  cursor: pointer;
  width: 165px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    color: #fff;
    font-weight: 500;
  }

  
}
@media (max-width: 480px) {
  .footer2{
    width: 343px;
    margin-bottom: 48px;
  }
}