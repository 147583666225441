.contact-form-container {
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    background: #fff;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    .close-icon {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
    }
    .contact-head {
        text-align: center;
        font-weight: 600;
        font-size: 18px;
    }

    .input-box {
        display: flex;
        flex-direction: column;
        gap: 10px;

        label {
            font-weight: 600;
            font-size: 14px;
        }
        input {
            padding: 15px;
            height: 45px;
            width: 100%;
            border: none;
            border-radius: 10px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        }
        textarea {
            padding: 15px;
            border: none;
            height: 80px;
            border-radius: 10px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        }
    }
    .btn {
        margin-top: 10px;
        button {
            height: 45px;
            background: #2869ff;
            width: 100%;
            color: #fff;
            border: none;
            border-radius: 10px;
            font-weight: 600;
            box-shadow: 0 16px 32px -8px rgb(10 124 255 / 32%), 0 4px 8px 0 rgb(10 124 255 / 12%);
            -webkit-transition: 0.3s;
        }
    }
    .message-sent {
        span {
            font-weight: 600;
            letter-spacing: 1px;
            text-align: center;
            display: block;
        }
    }
}
