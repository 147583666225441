.footerWrapper {
    background: #151313;
    color: #fff;
    padding: 60px 60px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .footerContainer {
      width: 100%;
      max-width: 1200px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .footerLinks {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 40px;
      width: 100%;
      padding-bottom: 30px;
  
      .footerGroup {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 300px;
  
        h3 {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 10px;
        }
  
        a {
          font-size: 14px;
          color: #bbb;
          text-decoration: none;
          transition: color 0.3s ease-in-out;
  
          &:hover {
            color: #fff;
          }
        }
  
        p {
          font-size: 14px;
          line-height: 1.6;
        }
  
        .logo {
          display: flex;
          align-items: center;
          gap: 10px;
  
          span {
            font-size: 18px;
            font-weight: 700;
          }
        }
      }
    }
  
    .copyRight {
      text-align: center;
      font-size: 14px;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      padding: 20px 0;
      width: 100%;
    }
  }
  
  @media (max-width: 1024px) {
    .footerWrapper {
      padding: 40px 20px;
    }
  
    .footerLinks {
      gap: 30px;
    }
  }
  
  @media (max-width: 770px) {
    .footerLinks {
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
    .footerGroup:first-child {
        grid-column: span 2; 
      }
  }
  
  @media (max-width: 430px) {
    .footerLinks {
      grid-template-columns: 1fr;
    }
    .footerGroup:first-child {
        grid-column: span 1;
      }
  
 
  }
  