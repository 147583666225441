.navbar-wrapper {
  max-width: 1344px;
  display: flex;
  justify-content: center;
  z-index: 5;
  margin: auto;

  .mobile-navigation {
    display: none;
  }

  .navbar {
    position: absolute;
    z-index: 1;
    width: 95%;
    max-width: 1344px;
    margin: 0px 48px;
    height: 62px;
    padding: 20px 2.2%;
    top: 27px;
    background: #2b2934;
    // opacity: 0.8;
    backdrop-filter: blur(5px);
    color: #eef3f8;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logoContainer {
      display: flex;
      align-items: center;
      flex-grow: 1;
      margin-right: 20px;

      .logoText {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        letter-spacing: -0.398997px;
        color: #eef3f8;
        margin-left: 10px;
      }
    }

    .list {
      list-style-type: none;
      display: flex;
      justify-content: center;
      flex-grow: 1;
      cursor: pointer;

      .listItem {
        padding: 0 15px;
        margin: 0 10px;
        display: flex;
        gap: 10px;
        

        span {
          position: relative;
          top: -6px;
        }

        &:hover {
          .dropdown {
            visibility: visible;
            opacity: 1;
            display: block;
            min-width: 250px;
            text-align: left;
            padding: 40px 0px;
            top: 35px;
            color: #eef3f8;
            border-radius: 20px;
            transition: all 15s;

            &:hover {
              .dropdown {
                visibility: visible;
                opacity: 1;
                display: block;
                min-width: 250px;
                text-align: left;
                padding: 0px 10px;
                top: 50px;
                transition: all 15s;
              }
            }
          }
        }

        .dropdown {
          visibility: hidden;
          list-style: none;
          opacity: 0;
          position: absolute;
          display: none;
          padding: 20px;
          transition: all 15s;
          // background: transparent;
          // backdrop-filter: blur(5px);
          // transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
          // transform: translateY(-10px);
          // transform: translateY(0);
          .dropdown-container {
            display: grid;
            grid-template-columns: 1fr; 
            row-gap: 10px; 
            column-gap: 20px;
            background: #2b2934;
            padding: 30px;
            border-radius: 20px;
            position: relative;
            left: -33.33%;
          

            &:has(.dropdown-item:nth-child(6)) {
              grid-template-columns: 1fr 1fr;
            }
          }
          
          .dropdown-item {
            padding: 5px 0px;
          
    
            &:nth-child(-n+5) {
              grid-column: 1;
            }
          

            &:nth-child(6) {
              grid-column: 2;
              grid-row: 1;
            }
          
          
            &:nth-child(n+7) {
              grid-column: 2;
              grid-row: 2;
            }
            .dropdown-item-detail {
              display: flex;
              flex-direction: row;
              gap: 20px;
              align-items: center;
              padding: 5px;
              .dropdown-item-detail-img {
                border: 1px solid #eaecf0;
                padding: 10px;
                color: white !important;
                border-radius: 10px;
                object-fit: contain;

                .img {
                  color: white;
                }
              }
              .dropdown-item-detail-content {
                display: flex;
                flex-direction: column;
                gap: 5px;
                .dropdown-item-detail-title {
                  font-size: 14px;
                }
                .dropdown-item-detail-des {
                  font-size: 12px;
                  color: #e7e8ea;
                }
              }

              &:hover {
                background-color: #44434b;
                border-radius: 10px;
                width: 100%;
              }
            }
          }
        }
      }
      .dropdown-container-home {
        background: linear-gradient(to right, #2e8bc0, #577ea5);

        // background: linear-gradient(to bottom right, #2c3e50, #4a5966);
        backdrop-filter: blur(10px);

        padding: 30px;
        border-radius: 20px;
        position: relative;
        left: -33.33%;
        // color: #2b2934;
        color: white;
      }
      .dropdown-item-home {
        padding: 10px 0px;

        &:hover {
          background: linear-gradient(116.56deg, #2665ff 0%, #36b1f2 100%);
          border-radius: 10px;
          width: 100%;
        }
      }
      .dropdown-item-detail-img-home {
        border: 1px solid #eaecf0;
        color: #2b2934;
        padding: 10px;
        border-radius: 10px;
        object-fit: contain;

        .img {
          color: #000;
        }
      }
      .dropdown-item-detail-des-home {
        color: white;
        font-size: 12px;
      }
      // mega-dropdown code
      // .mega-drop {
      //   position: absolute;
      //   margin-top: 45px;
      //   transform: translateX(-40%);
      //   width: 600px;
      //   height: 400px;
      //   border-radius: 5px;
      //   background-color: white;
      //   color: black;
      // }

      // .mega-grid {
      //   display: grid;
      //   grid-template-rows: 1fr 2fr 1fr;
      //   height: 100%;
      //   padding: 25px;
      // }

      // .mega-child-first {
      //   border: 0px 0px 2px 0px solid black;
      // }
    }

    .buttonDiv {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
    }

    .more {
      display: none;
    }
  }

  .hamburger {
    display: none;
  }

  .hide-hamburger {
    display: none;
  }
}

@media (max-width: 870px) {
  .navbar-wrapper {
    .navbar {
      display: none;
    }

    .mobile-logo {
      display: flex;
      align-items: center;
      gap: 5px;

      span {
        font-weight: 500;
        color: #fff;
        font-size: 16px;
      }
    }

    .mobile-navigation {
      display: flex;
      align-items: center;
      // justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 50px;
      width: 100%;
      height: 56px;
      z-index: 1000;
      background: #2b2934;
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
    }

    .logo {
      width: 100%;
      height: 100%;
    }

    .logo img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    #menuToggle {
      display: block;
      position: absolute;
      top: 18px;
      left: 15px;
      z-index: 1;
      -webkit-user-select: none;
      user-select: none;
    }

    #menuToggle a {
      text-decoration: none;
      color: #232323;

      transition: color 0.3s ease;
    }

    // #menuToggle a:hover {
    //   color: tomato;
    // }

    #menuToggle input {
      display: block;
      width: 40px;
      height: 32px;
      position: absolute;
      top: -7px;
      left: -5px;
      cursor: pointer;
      opacity: 0;
      z-index: 2;
      -webkit-touch-callout: none;
    }

    #menuToggle {
      label {
        display: block;
        height: 0;
        width: 0;
        overflow: hidden;
      }

      span {
        display: block;
        width: 28px;
        height: 3px;
        margin-bottom: 5px;
        position: relative;
        background: #fff;
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
          background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
      }
    }

    #menuToggle.dark {
      span {
        background: #000;
      }
    }

    #menuToggle span:first-child {
      transform-origin: 0% 0%;
    }

    #menuToggle span:nth-last-child(2) {
      transform-origin: 0% 100%;
    }

    #menuToggle input:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(-2px, -1px);
      background: #fff;
    }

    #menuToggle input:checked ~ span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    #menuToggle input:checked ~ span:nth-last-child(2) {
      transform: rotate(-45deg) translate(0, -2px);
    }

    #menu {
      position: fixed;
      width: 100%;
      min-height: 100vh;
      height: auto;
      top: 0;
      left: 0;
      margin: 0;
      /* padding: 30px; */
      padding-top: 70px;
      background: linear-gradient(
        90deg,
        rgba(15, 16, 20, 0.95) 5.24%,
        rgba(15, 16, 20, 0.64) 98.46%
      );
      list-style-type: none;
      -webkit-font-smoothing: antialiased;
      /* to stop flickering of text in safari */
      transform-origin: 0% 0%;
      transform: translate(-100%, 0);
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    }

    #menu li {
      // align-items: center;
      display: flex;
      font-size: 18px;
      font-weight: 500;
      /* justify-content: center; */
      padding: 15px 35px;
      // border-bottom: 1px solid #0000006b;
      transition: all 0.4s ease-in-out;

      // &:hover {
      //   transform: scale(1.1);
      //   transform-origin: 0% 25%;
      // }

      button {
        border: none;
        outline: none;
        background: transparent;
        color: #fff;
        font-weight: 600;
      }
    }

    #menuToggle input:checked ~ ul {
      transform: none;
    }
.dropdown-parent {
  display: flex;
  flex-direction: column;
  
  button {
    display: flex;
    align-items: center;
    width: 100%;
  }
}
.dropdown-parent.open {
  padding-bottom: 0;
}
.dropdown-menu {
  list-style: none;
  padding-left: 20px;
  margin-top: 5px;
  transform: translateY(-10px);

  li {
    padding:5px !important;
    align-items: cen !important;
      display: flex;
      transition: all 0.4s ease-in-out;
    
    button {
      // font-size: 16px;
      font-weight: 500;
      padding: 10px 0px;
    }
  }
}
.dropdown-menu li:first-child {
  padding-top: 20px !important;
}

.dropdown-menu li:last-child {
  padding-bottom: 0 !important;
}
.dropdown-arrow {
  margin-left: 10px;
  font-size: 12px;
  width: 0px !important;
  height:20px;
}

  }
}
